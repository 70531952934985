import { addClass, removeClass } from '../../assets/js/utility';

class TabList {
  constructor() {
    this.tabListClass = '.dynamic-tablist';
    this.tabLists = document.querySelectorAll(`${this.tabListClass}`);
    this.overflowClass = 'is-overflowing';

    if (this.tabLists && this.tabLists.length > 0) {
      // check if tablist is overflowing after a window resize
      window.addEventListener('resize', () => {
        this.tabLists.forEach((list) => {
          this.checkOverflow(list);
        });
      });

      this.tabLists.forEach((tabbedList) => {
        // change glider behavior if there's overflow
        this.checkOverflow(tabbedList);
      });
    }
  }

  checkOverflow(el) {
    let hasOverflow =
      el.clientWidth < el.scrollWidth || el.clientWidth > window.innerWidth;

    if (hasOverflow) {
      addClass(el, this.overflowClass);
    } else {
      removeClass(el, this.overflowClass);
    }
  }
}

export default TabList;
