import { addClass } from '../../assets/js/utility';

class Content {
  constructor() {
    // all content links and nav menu sublinks to apply external class to
    this.externalClass = 'external';
    this.externalLinks = document.querySelectorAll(
      '.content a[href]:not(.button), .content-sidebar a[href]:not(.button), ' +
        '.dynamic-nav .submenu a[href], .dynamic-resource-links-list a[href]'
    );

    if (this.externalLinks.length > 0) {
      for (let i = 0; i < this.externalLinks.length; i++) {
        var link = this.externalLinks[i];

        // exclude inline js, mailto, tel, etc links
        if (link.href.indexOf('://') > 0) {
          let hostnameParts = location.hostname.split('.');
          let rootDomain = '';

          // location.hostname includes subdoms which we don't want to compare with
          // poor mans detection expecting simple subdom.rootdom.com format
          // what we want should be 2nd last unless on localhost
          if (hostnameParts.length > 1) {
            rootDomain = hostnameParts[hostnameParts.length - 2];
          } else {
            rootDomain = hostnameParts[0];
          }

          if (link.hostname.indexOf(rootDomain) < 0) {
            addClass(link, this.externalClass);
          }
        }
      }
    }

    // insert a word break opportunity for emails
    this.mailToLinks = document.querySelectorAll('a[href^=mailto]');

    if (this.mailToLinks.length > 0) {
      for (let mailto of this.mailToLinks) {
        var formatted = mailto.innerText.replace(/([@.])/g, '<wbr>$1');

        // check for complex link element like resource or nav
        // so we don't destroy markup
        let spanLayer = mailto.querySelector('span');
        if (spanLayer != null) {
          spanLayer.innerHTML = formatted;
        } else {
          mailto.innerHTML = formatted;
        }
      }
    }

    // all images wrapped in an anchor tag within content sections
    this.linkedImageClass = 'img-link';
    this.linkedImages = document.querySelectorAll(
      '.content a > img, a.content > img'
    );

    if (this.linkedImages.length > 0) {
      this.linkedImages.forEach((link) =>
        addClass(link.parentElement, this.linkedImageClass)
      );
    }

    // all content images use their alt text as their title
    this.allContentImages = document.querySelectorAll('.content img');
    if (this.allContentImages.length > 0) {
      this.allContentImages.forEach((image) => {
        let imgAltText = image.getAttribute('alt');

        if (imgAltText != '') {
          image.setAttribute('title', imgAltText);
        }
      });
    }
  }
}

export default Content;
