import { wrapElement, addClass } from '../../assets/js/utility';

class ResponsiveTables {
  constructor() {
    // May want to tweak if any tables are used for non-data purposes in project
    this.tablesList = document.querySelectorAll('table');
    this.wrapperClass = 'table-wrapper';
    this.stackedClass = 'stacked-table';
    this.stickyHorizontalClass = 'sticky-left-col';
    this.stackedHeaderClass = 'stacked-table-header';
    this.cellHeadingClass = 'cell-heading';

    if (this.tablesList && this.tablesList.length > 0) {
      this.tablesList.forEach((table) => {
        // avoid double wrapping if wrapper already exists
        if (table.classList.contains(this.stickyHorizontalClass)) {
          if (!table.parentElement.classList.contains(this.wrapperClass)) {
            // create & insert the wrapper element
            wrapElement(table, document.createElement('div'), this.wrapperClass);
          }

          this.setLabelColumn(table);
        } else {
          // add responsive class for styles if not already there
          addClass(table, this.stackedClass);
          this.setupStackedTable(table);
        }
      });
    }
  }

  /**
   * Assigns a set width for 1st column to remain visible on horizontal scroll with the wrapper shadow
   * properly set at its edge. Based on auto-fit size determined on load, or a hardset width if too big
   * @param {Element} el - table element node
   * @returns
   */
  setLabelColumn(el) {
    let width = 0;
    const largestLabelWidth = 190; // in px, keep small for mobile considerations
    const rows = el.querySelectorAll('tr');

    for (let i = 0; i < rows.length; i++) {
      const tdFirst = rows[i].querySelector('td'); // first column in row

      if (tdFirst) {
        // special case: check if only 2 columns. If so, unset absolute positioning
        if (rows[i].cells.length === 2 && tdFirst.getAttribute('colspan') === null) {
          for (let c = 0; c < rows.length; c++) {
            // unset sticky on all heading & content rows in the table
            rows[c].querySelector('th,td').style.position = 'relative';
            rows[c].querySelector('th,td').style.backgroundColor = '#fff0'; // adjust as needed for project
          }

          return;
        }

        // check column isn't spanning to avoid skewing normal columns
        if (tdFirst.getAttribute('colspan') == null) {
          // set the label column width as is, or cap at declared largest size if too big
          width =
            tdFirst.clientWidth <= largestLabelWidth
              ? tdFirst.clientWidth
              : largestLabelWidth;
          tdFirst.style.width = `${width + 1}px`; // account for sticky position -1 left

          // set wrapper shadow offset to match width of 1st column
          const wrapper = tdFirst.closest('.table-wrapper');
          wrapper.style.backgroundPosition = `${width}px center, right center, ${width}px center, right center`;

          return;
        }
      }
    }
  }

  /**
   * Performs setup for table stacking on the given element
   * @param {Element} element Table element node
   */
  setupStackedTable(element) {
    // Don't setup the table when it's already been set up
    if (element.dataset.initialized == 'true') {
      return;
    }
    element.dataset.initiated = true;

    // Determine what the header should be
    // - thead, if applicable
    // - first tr of tbody, if no thead
    let header = element.querySelector('thead tr');
    if (!header) {
      header = element.querySelector('tr:first-child');

      // in cases of bad table markup where there is a 'ghost' still around
      // that has the table & tbody markup but nothing inside
      if (!header) {
        return;
      }

      addClass(header, this.stackedHeaderClass);
    }

    const headingCells = Array.from(header.querySelectorAll('th, td'));

    const rows = Array.from(
      element.querySelectorAll(`tbody tr:not(.${this.stackedHeaderClass}), tfoot tr`)
    );

    // Create template label elements for headings
    const headingLabels = headingCells.map((cell) => {
      const newElement = document.createElement('span');
      newElement.innerHTML = cell.innerHTML;
      newElement.classList.add(this.cellHeadingClass);

      return newElement;
    });

    // Apply new headings
    for (const row of rows) {
      const cells = Array.from(row.querySelectorAll('td, th'));

      for (let i = 0; i < cells.length; i++) {
        const heading = headingLabels[i].cloneNode(true);
        const cell = cells[i];

        cell.insertAdjacentElement('afterbegin', heading);
      }
    }
  }
}

export default ResponsiveTables;
