import {
  PAGE_HASH_DATA,
  scrollToContainer,
  addClass,
  toggleClass,
  toggleAttribute,
  slideDownTogglableSection,
} from '../../assets/js/utility';

class Accordion {
  constructor() {
    this.toggleClass = 'is-open';
    this.accordionItemClass = '.simple-accordion-item';
    this.accordionHeadings = document.querySelectorAll(
      `${this.accordionItemClass} .accordion-item-heading`
    );

    // each item works independently so don't need to care about the section
    // container itself
    if (this.accordionHeadings && this.accordionHeadings.length > 0) {
      this.InitAccordionItems();
      this.watchRelatedAnchors();
    }
  }

  watchRelatedAnchors() {
    if (PAGE_HASH_DATA.allAnchorHashValues.length <= 0) {
      return;
    }

    // check if any # links match an accordion tiem & add to relevant list
    let allAccordionIds = [...this.accordionHeadings].map(({ id }) => id);
    let relevantLinkHashes = [];

    allAccordionIds.filter((id) => {
      if (PAGE_HASH_DATA.allAnchorHashValues.indexOf(`#${id}`) !== -1) {
        relevantLinkHashes.push(`#${id}`);
      }
    });

    // pair down relevant anchor elements for listeners
    let relevantAnchorElements = [...PAGE_HASH_DATA.allAnchorHashElements].filter(
      (element) => {
        if (relevantLinkHashes.indexOf(element.hash) !== -1) {
          return element;
        }
      }
    );

    relevantAnchorElements.forEach((anchor) => {
      anchor.addEventListener('click', (e) => {
        let targetHash = e.target.hash;
        let currentElement = document.querySelector(
          `${this.accordionItemClass} ${targetHash}`
        );

        e.preventDefault(); // prevent normal snap to element
        currentElement.setAttribute('aria-expanded', 'true');
        addClass(currentElement.closest(this.accordionItemClass), this.toggleClass);

        // resume normal behavior but with smooth scroll
        window.location.hash = targetHash;
        scrollToContainer(currentElement, this.accordionItemClass);
      });
    });
  }

  InitAccordionItems() {
    this.accordionHeadings.forEach((heading) => {
      if (
        PAGE_HASH_DATA.windowHashValue != '' &&
        heading.id == PAGE_HASH_DATA.windowHashValue
      ) {
        // jump to matching item on load & open it
        heading.setAttribute('aria-expanded', 'true');
        addClass(heading.closest(this.accordionItemClass), this.toggleClass);
        scrollToContainer(heading, this.accordionItemClass);
      }

      heading.addEventListener('click', (e) => {
        let accordionItem = e.target.closest(this.accordionItemClass);
        if (!accordionItem) {
          return;
        }

        slideDownTogglableSection(e, this.accordionItemClass, '.content');
        toggleAttribute(heading, 'aria-expanded');
        toggleClass(accordionItem, this.toggleClass);
      });
    });
  }
}

export default Accordion;
