import {
  PAGE_HASH_DATA,
  scrollToContainer,
  addClass,
  removeClass,
  slideDownTogglableSection,
} from '../../assets/js/utility';

class TabbedContent {
  constructor() {
    this.activeClass = 'is-active';
    this.tabbedContentContainerClass = '.dynamic-tabbed-content';
    this.tabListClass = '.dynamic-tablist';
    this.tabLists = document.querySelectorAll(
      `${this.tabbedContentContainerClass} ${this.tabListClass}`
    );

    if (this.tabLists && this.tabLists.length > 0) {
      this.InitTabbedContent();
    }
  }

  /**
   * Sets active states on given tab & panel elements that are not
   * animated by the slideToggle script
   * @param {Node Element} tabElement
   * @param {Node Element} panelElement
   */
  setNonAnimatedTabAndPanel(tabElement, panelElement) {
    if (tabElement && panelElement) {
      tabElement.setAttribute('aria-selected', 'true');
      addClass(tabElement, this.activeClass);
      addClass(panelElement, this.activeClass);

      // set height for smooth slide animation if users click on tab
      let startHeight = panelElement.scrollHeight + 64; // match adjustment from utility script
      panelElement.style.maxHeight = startHeight + 'px';
    }
  }

  /**
   * Sets the provided tab element & its corresponding content panel
   * to an active state
   * @param {event} e - (optional) if user clicks a tab
   * @param {Node Element} tabElement - tab heading element to set active
   * calls removeActiveStateOfPrevious
   */
  setSelectedTab(e = null, tabElement) {
    if (!tabElement) {
      return;
    }

    if (e) {
      // tab is clicked
      let correspondingContentPanel = document.querySelector(
        '#tabContent-' + tabElement.id
      );

      tabElement.setAttribute('aria-selected', 'true');
      addClass(tabElement, this.activeClass);
      addClass(correspondingContentPanel, this.activeClass);

      // open panel
      slideDownTogglableSection(
        e,
        this.tabbedContentContainerClass,
        `#tabContent-${tabElement.id}`,
        this.activeClass
      );
    } else {
      // # anchor clicked directing to tab,
      // won't have event context for panel
      let correspondingContentPanel = document.querySelector(
        '#tabContent-' + tabElement.id
      );

      this.setNonAnimatedTabAndPanel(tabElement, correspondingContentPanel);
    }

    // find old active tab & remove active state
    this.removeActiveStateOfPrevious(tabElement);
  }

  /**
   * Called by SetSelectedTab. Removes active state of tab that does not match
   * the current tab element it is given
   * @param {Node Element} currentTabElement - tab heading element being set active
   */
  removeActiveStateOfPrevious(currentTabElement) {
    let tabList = currentTabElement
      ? currentTabElement.closest(`${this.tabListClass}`)
      : null;
    let containingElement = currentTabElement
      ? currentTabElement.closest(this.tabbedContentContainerClass)
      : null;

    if (!tabList || !containingElement) {
      return;
    }

    // check each sibling in the tablist for the previously active tab
    Array.from(tabList.children).forEach((tab) => {
      let tabId = tab.getAttribute('id');

      if (
        tabId != currentTabElement.id &&
        tab.classList.contains(this.activeClass)
      ) {
        // found the previously active tab
        let previousContentPanel = containingElement.querySelector(
          '#tabContent-' + tabId
        );

        tab.setAttribute('aria-selected', 'false');
        removeClass(tab, this.activeClass);

        // remove active settings from corresponding content panel
        if (previousContentPanel) {
          removeClass(previousContentPanel, this.activeClass);
          previousContentPanel.style.removeProperty('max-height');
        }
      }
    });
  }

  /**
   * Calls setSelectedTab & scrollToContainer when a user clicks a tab
   * @param {event} e - user clicked on a tab heading
   */
  onTabClick(e) {
    let clickedTab = e.target.closest('.tab-heading'); // in case clicked on svg in button
    if (!clickedTab) {
      return;
    }

    // get content panels from same container
    let tabList = clickedTab.closest(`${this.tabListClass}`);
    let containingElement = clickedTab.closest(this.tabbedContentContainerClass);
    if (!containingElement || !tabList) {
      return;
    }

    if (containingElement.querySelector('#tabContent-' + clickedTab.id)) {
      this.setSelectedTab(e, clickedTab);
    }

    scrollToContainer(clickedTab, this.tabbedContentContainerClass);
  }

  /**
   * @param {string array} jumpToElementIds - ids of all elements to watch for matching anchors
   */
  watchRelatedAnchors(jumpToElementIds) {
    if (PAGE_HASH_DATA.allAnchorHashValues.length <= 0) {
      return;
    }

    // check if any # links match a tab & add to relevant list
    let relevantLinkHashes = [];

    jumpToElementIds.filter((id) => {
      if (PAGE_HASH_DATA.allAnchorHashValues.indexOf(`#${id}`) !== -1) {
        relevantLinkHashes.push(`#${id}`);
      }
    });

    // pair down relevant anchor elements for listeners
    let relevantAnchorElements = [...PAGE_HASH_DATA.allAnchorHashElements].filter(
      (element) => {
        if (relevantLinkHashes.indexOf(element.hash) !== -1) {
          return element;
        }
      }
    );

    relevantAnchorElements.forEach((anchor) => {
      anchor.addEventListener('click', (e) => {
        let targetHash = e.target.hash;
        let currentElement = document.querySelector(
          `${this.tabbedContentContainerClass} ${targetHash}`
        );

        e.preventDefault(); // prevent normal snap to element
        this.setSelectedTab(null, currentElement);

        // resume normal behavior but with smooth scroll
        window.location.hash = targetHash;
        scrollToContainer(currentElement, this.tabbedContentContainerClass);
      });
    });
  }

  InitTabbedContent() {
    // Begin setting all tab list interactions on page
    this.tabLists.forEach((tabbedList) => {
      // tab headings
      let tabs = tabbedList.querySelectorAll('[role=tab]');
      let allTabIds = [...tabs].map(({ id }) => id);

      // get content panels from same container
      let containingElement = tabbedList.closest(this.tabbedContentContainerClass);
      let tabPanels = containingElement
        ? containingElement.querySelectorAll('[role=tabpanel]')
        : null;

      if (!tabPanels || tabPanels.length <= 0) {
        console.info('No tab panels found');
        return;
      }

      // default init
      let initialTab = tabs[0];
      let initialPanel = tabPanels[0];

      // determine if init should be based on url #
      if (
        PAGE_HASH_DATA.windowHashValue != '' &&
        allTabIds.indexOf(PAGE_HASH_DATA.windowHashValue) >= 0
      ) {
        // found matching tab to direct to
        let initIndex = allTabIds.indexOf(PAGE_HASH_DATA.windowHashValue);

        initialTab = tabs[initIndex];
        initialPanel = tabPanels[initIndex];
        scrollToContainer(initialTab, this.tabbedContentContainerClass);
      }

      // Init 1st tab & content on load
      this.setNonAnimatedTabAndPanel(initialTab, initialPanel);

      // set listeners
      tabs.forEach((tab) =>
        tab.addEventListener('click', this.onTabClick.bind(this))
      );

      this.watchRelatedAnchors(allTabIds);
    });
  }
}

export default TabbedContent;
