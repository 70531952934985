import {
  addClass,
  removeClass,
  slideDownTogglableSection,
} from '../../assets/js/utility';

class PageFeedbackForm {
  constructor() {
    this.toggleClass = 'is-open';
    this.positiveSubmitClass = 'is-submitted';
    this.formSubmitClass = 'feedback-success';
    this.componentClass = '.simple-page-feedback-form';
    this.formAreaClass = '.feedback-form';
    this.messageClass = '.positive-submit-message';

    // should be unique on page
    this.feedbackform = document.querySelector(this.componentClass);

    if (this.feedbackform) {
      this.initPageFeedbackForm();
    }
  }

  initPageFeedbackForm() {
    let positive = this.feedbackform.querySelector('#feedbackPositive');
    let negative = this.feedbackform.querySelector('#feedbackNegative');
    let formArea = this.feedbackform.querySelector(this.formAreaClass);
    let messageContainer = this.feedbackform.querySelector(this.messageClass);

    // if part of the required pieces are missing don't show the component at all
    if (!positive || !negative || !formArea || !messageContainer) {
      this.feedbackform.style.display = 'none';
      this.feedbackform.innerHTML = '';
      console.warning('Page feedback form is missing an initialization piece.');
      return;
    }

    // check if this is a page load after form submit success
    if (this.feedbackform.querySelector('.umbraco-forms-submitmessage')) {
      // show the set message from component since form workflow unreliable to show
      addClass(this.feedbackform, this.formSubmitClass);
      addClass(formArea, this.toggleClass);

      // disable the buttons
      positive.disabled = true;
      negative.disabled = true;
    }

    positive.addEventListener('click', (e) => {
      // set as submitted and show thank you
      addClass(this.feedbackform, this.positiveSubmitClass);
      addClass(messageContainer, this.toggleClass);
      slideDownTogglableSection(e, this.componentClass, this.messageClass);

      // disable the negative
      negative.disabled = true;

      // if negative was clicked before, close form
      removeClass(formArea, this.toggleClass);
      formArea.style.removeProperty('max-height');
    });

    negative.addEventListener('click', (e) => {
      // don't show form if user already submitted positive feedback
      if (this.feedbackform.classList.contains(this.positiveSubmitClass)) {
        return;
      }

      // only open once
      let isOpen = formArea.classList.contains(this.toggleClass);

      if (!isOpen) {
        addClass(formArea, this.toggleClass);
        slideDownTogglableSection(e, this.componentClass, this.formAreaClass);
      }
    });
  }
}

export default PageFeedbackForm;
