import {
  toggleClass,
  toggleAttribute,
  slideDownTogglableSection,
  removeClass,
  addClass,
} from '../../assets/js/utility';

class Header {
  constructor() {
    this.scrollClass = 'is-scrolled';
    this.toggleClass = 'is-open';
    this.mobileMembersOpenClass = 'members-is-open';
    this.menuLeaveTimer;
    this.mobileMenuBreakpoint = 870; // md

    this.siteHeaderElement = document.querySelector('.site-header');
    this.headerNav = document.querySelector('.site-header .dynamic-header-nav');
    this.memberMenuButton = document.querySelector(
      '.site-header .member-menu-button'
    );
    this.memberMenuArea = document.querySelector(
      '.site-header .member-logged-group'
    );
    this.memberMenu = document.querySelector('.site-header .dynamic-mega-menu');

    this.mobileMemberIcon = document.querySelector(
      '.site-header .mobile-members-menu-icon'
    );
    this.menuIcon = document.querySelector('#mobile-menu-icon');

    // for mobile scrolling
    if (this.siteHeaderElement) {
      let ticking = false;

      window.addEventListener('resize', () => this.checkForScroll());
      window.addEventListener('scroll', () => {
        if (!ticking) {
          window.requestAnimationFrame(() => {
            this.checkForScroll();
            ticking = false;
          });

          ticking = true;
        }
      });

      // always check on load in case page using jump link
      this.checkForScroll();
    }

    if (this.headerNav) {
      // desktop mega menu states
      // specifically add/remove and not toggle cause if page loads w/ user mouse over an area
      // the menus will not toggle backwards
      if (this.memberMenuButton && this.memberMenu) {
        this.memberMenuButton.addEventListener('mouseenter', (e) => {
          slideDownTogglableSection(e, '.member-logged-group', '.dynamic-mega-menu');
          addClass(this.memberMenuButton, this.toggleClass);
          addClass(this.memberMenuArea, this.toggleClass);
        });

        this.memberMenuButton.addEventListener('mouseleave', (e) => {
          // stay open if focusing in submenu
          if (this.memberMenu.contains(e.relatedTarget)) {
            return;
          } else {
            // don't close immediately in case mouse traveling outside of
            // menu area to reach distant menu panel
            this.menuLeaveTimer = setTimeout(() => {
              slideDownTogglableSection(
                e,
                '.member-logged-group',
                '.dynamic-mega-menu'
              );
              removeClass(this.memberMenuButton, this.toggleClass);
              removeClass(this.memberMenuArea, this.toggleClass);
            }, 800);
          }
        });

        this.memberMenu.addEventListener('mouseenter', (e) => {
          // clear any leave timer if mouse traveled over non-menu elements
          // on its way here
          clearTimeout(this.menuLeaveTimer);

          slideDownTogglableSection(e, '.member-logged-group', '.dynamic-mega-menu');
          addClass(this.memberMenuButton, this.toggleClass);
          addClass(this.memberMenuArea, this.toggleClass);
        });

        this.memberMenu.addEventListener('mouseleave', (e) => {
          slideDownTogglableSection(e, '.member-logged-group', '.dynamic-mega-menu');
          removeClass(this.memberMenuButton, this.toggleClass);
          removeClass(this.memberMenuArea, this.toggleClass);
        });

        this.memberMenu.addEventListener('focusin', (e) => {
          // stay open if focusing in submenu
          if (this.memberMenu.contains(e.relatedTarget)) {
            return;
          }

          slideDownTogglableSection(e, '.member-logged-group', '.dynamic-mega-menu');
          addClass(this.memberMenuButton, this.toggleClass);
          addClass(this.memberMenuArea, this.toggleClass);
        });

        this.memberMenu.addEventListener('focusout', (e) => {
          // stay open if focusing in submenu
          if (this.memberMenu.contains(e.relatedTarget)) {
            return;
          }

          slideDownTogglableSection(e, '.member-logged-group', '.dynamic-mega-menu');
          removeClass(this.memberMenuButton, this.toggleClass);
          removeClass(this.memberMenuArea, this.toggleClass);
        });
      }

      // Mobile Specific elements
      if (this.menuIcon) {
        this.menuIcon.addEventListener('click', () => {
          // check if was opened by members icon
          if (this.menuIcon.classList.contains(this.mobileMembersOpenClass)) {
            toggleClass(this.menuIcon, this.mobileMembersOpenClass);
            toggleClass(this.headerNav, this.mobileMembersOpenClass);
          } else {
            toggleClass(this.menuIcon, this.toggleClass);
          }

          toggleAttribute(this.menuIcon, 'aria-expanded');
        });
      }

      if (this.mobileMemberIcon) {
        this.mobileMemberIcon.addEventListener('click', () => {
          // uses same menu area & close button,
          // but controls the member specific portion
          toggleClass(this.menuIcon, this.mobileMembersOpenClass);
          toggleClass(this.headerNav, this.mobileMembersOpenClass);
          toggleAttribute(this.menuIcon, 'aria-expanded');
        });
      }
    }
  }

  /**
   * applies style to header if on mobile & scrolled
   */
  checkForScroll() {
    if (window.innerWidth > this.mobileMenuBreakpoint) return;

    if (window.scrollY + this.siteHeaderElement.getBoundingClientRect().top > 10) {
      addClass(this.siteHeaderElement, this.scrollClass);
    } else {
      removeClass(this.siteHeaderElement, this.scrollClass);
    }
  }
}
export default Header;
