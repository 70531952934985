class Footer {
  constructor() {
    this.copyrightElement = document.querySelector('.site-footer #copyright');

    if (this.copyrightElement) {
      let copy = '&copy; ' + new Date().getFullYear();
      this.copyrightElement.insertAdjacentHTML('afterbegin', copy);
    }
  }
}

export default Footer;
