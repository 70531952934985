class Footer {
  constructor() {
    this.copyrightElement = document.querySelector('.site-footer #copyright');

    if (this.copyrightElement) {
      this.copyrightElement.insertAdjacentText(
        'afterbegin',
        new Date().getFullYear()
      );
    }
  }
}

export default Footer;
