import {
  addClass,
  toggleClass,
  slideDownTogglableSection,
} from '../../assets/js/utility';

class Nav {
  constructor() {
    this.toggleClass = 'is-open';
    this.linksWithDropdown = document.querySelectorAll('.dynamic-nav .has-sublinks');
    this.activeLink = document.querySelectorAll('.dynamic-nav .is-active');

    // set dropdown show/hide listener
    if (this.linksWithDropdown) {
      // for preventing duplicate event triggers
      let navActive = false;
      let allowArrowDownTrigger = false;

      for (let i = 0; i < this.linksWithDropdown.length; i++) {
        let currentNavLink = this.linksWithDropdown[i];

        // mouse events
        currentNavLink.addEventListener('mouseenter', (e) => {
          if (navActive == false) {
            navActive = true;
            this.changeOpenState(e, currentNavLink, 'true');

            // in case triggered by an arrow down click
            // don't allow trigger from same event
            setTimeout(function () {
              allowArrowDownTrigger = true;
            }, 600);
          }
        });

        currentNavLink.addEventListener('mouseleave', (e) => {
          if (navActive == true) {
            navActive = false;
            this.changeOpenState(e, currentNavLink);

            // prevent arrow click triggers until next mouseenter
            allowArrowDownTrigger = false;
          }
        });

        // keyboard events
        currentNavLink.addEventListener('focusin', (e) => {
          // stay open if focusing in submenu
          if (currentNavLink.contains(e.relatedTarget) || navActive === true) {
            return;
          }

          navActive = true;
          this.changeOpenState(e, currentNavLink, 'true');
        });

        currentNavLink.addEventListener('focusout', (e) => {
          // stay open if focusing in submenu
          if (currentNavLink.contains(e.relatedTarget) || navActive === false) {
            return;
          }

          navActive = false;
          this.changeOpenState(e, currentNavLink);
        });

        // for mobile
        let downArrow = currentNavLink.querySelector('.arrow-down');

        if (downArrow) {
          downArrow.addEventListener('mousedown', (e) => {
            if (allowArrowDownTrigger == true) {
              // if not open, tell changeState to open it
              let openSubmenu = !currentNavLink.classList.contains(this.toggleClass);

              this.changeOpenState(
                e,
                currentNavLink,
                openSubmenu ? 'true' : 'false'
              );
            }
          });
        }
      }
    }

    // if dropdown item active, indicate on 1st level & dropdown item
    if (this.activeLink) {
      this.activeLink.forEach((link) => {
        let navContainer = link.closest('.dynamic-nav');
        let parentItem = link.closest('.has-sublinks');

        if (parentItem && !navContainer.classList.contains('horizontal')) {
          addClass(parentItem, this.toggleClass);
          parentItem.setAttribute('aria-expanded', true);
        }
      });
    }
  }

  /**
   * Checks if submenu needs to be opened or not & sets the state if so
   * @param {Event} e - event trigger for animation
   * @param {Node Element} group - nav item (li) element
   * @param {string} makeExpanded - aria-expanded value to set
   * @param {string} toggleClass - class to toggle on the group element
   */
  changeOpenState(e, group, makeExpanded = 'false') {
    let navContainer = group.closest('.dynamic-nav');
    let linkElement = group.querySelector('.nav-link');
    let currentState = 'false';

    // is-active in vertical should always be open
    let ignoreToggle =
      !navContainer.classList.contains('horizontal') &&
      group.classList.contains('is-active');

    if (linkElement) {
      currentState = linkElement.getAttribute('aria-expanded');

      if (currentState != makeExpanded && !ignoreToggle) {
        linkElement.setAttribute('aria-expanded', makeExpanded);

        // can't go outside if-statement cause must happen before class change
        slideDownTogglableSection(e, '.has-sublinks', '.submenu');
        toggleClass(group, this.toggleClass);
      }
    }
  }
}

export default Nav;
